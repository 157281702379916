import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMain,{staticClass:"fill-height d-flex align-center pa-4 fondo-image",staticStyle:{"background-color":"black"},attrs:{"fluid":""}},[_c(VRow,{staticClass:"justify-center"},[_c(VCol,{attrs:{"cols":"12","sm":"7","md":"5","lg":"4"}},[_c(VCardText,{staticClass:"text-center"},[_c('div',{staticClass:"texto-invitacion"},[_vm._v(_vm._s(_vm.textoInvitacion))])]),_c('br'),_c('br'),(_vm.formularioVisible)?_c(VCardText,{staticClass:"py-0 px-2 fade-in"},[[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{staticClass:"pass",attrs:{"append-icon":"lock","append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show1 ? 'text' : 'password',"label":"Ingresa código de acceso","placeholder":"**********","outlined":"","color":"white","dark":""},on:{"click:append":function($event){_vm.show1 = !_vm.show1},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.ingresar.apply(null, arguments)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]],2):_vm._e(),(_vm.formularioVisible)?_c(VCardActions,[_c(VBtn,{staticClass:"fade-in",attrs:{"persistent":"","color":"primary","dark":"","block":""},on:{"click":function($event){return _vm.ingresar()}}},[_vm._v(" Ingresar ")])],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }