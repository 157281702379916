import Vue from 'vue'
export default {

    computed: {

        // pantallaAlumnos() {
        //     return this.$vuetify.breakpoint.name == 'xs' ? 'auto' :
        //         this.$vuetify.breakpoint.height - 390
        // },
        tamanioPantalla() {
            return this.$vuetify.breakpoint.height - 240
        },

        tamanioBanner() {
            return this.$vuetify.breakpoint.height - 10
        }
    },

    methods: {

        traerFechaActual() {
            var f = new Date();
            return f.getFullYear() + '-' + (f.getMonth() + 1 < 10 ? '0' + (f.getMonth() + 1) : f.getMonth() + 1) + '-' + (f.getDate() < 10 ? '0' + f.getDate() : f.getDate());
        },

        obtenerDíasRestantes(){
            const fechaActual = new Date(); // Obtén la fecha actual
            const fechaLimite = new Date('2024-05-01'); // Establece la fecha límite (1 de mayo de 2024)
            const diferenciaEnMilisegundos = fechaLimite - fechaActual; // Calcula la diferencia en milisegundos
            const diferenciaEnDias = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24)); // Convierte la diferencia de milisegundos a días
            return diferenciaEnDias;
          }
    }
}