import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import ScrollReveal from 'scrollreveal';
import VueResource from 'vue-resource'


Vue.config.productionTip = false
Vue.prototype.$scrollReveal = ScrollReveal();
Vue.use(VueResource)


// Vue.http.options.root = 'http://localhost:3025/' // ROOT PARA PODUCCON 
Vue.http.options.root = 'https://sofsolution.club/wedding-api/' // ROOT PARA PODUCCON 

Vue.http.interceptors.push((request, next) => {
    // request.headers.set('Authorization', 'Bearer ' + localStorage.tlaKey)
    request.headers.set('Accept', 'application/json')
    next()
});
  
new Vue({
    router,
    store,
    vuetify,
    render: function(h) { return h(App) }
}).$mount('#app')