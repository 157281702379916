import Vue from 'vue'
import Vuex from 'vuex'
import metodos from '@/mixins/metodos.js';

Vue.use(Vuex)

export default new Vuex.Store({
    mixins:[metodos],
    state: {
        invitados: [],
        invitadoSesion: {}
    },

    getters: {
        obtenerInvitados(state) {
            return state.invitados;
        },

        obtenerSesionInvitado(state){
            return state.invitadoSesion
        }
    },

    mutations: {
        setDatosInvitados(state, data) {
            state.invitados = data;
        },

        setSesionInvitados(state, invitado){
            state.invitadoSesion = invitado
        },
        updateSesionInvitados(state, invitado){
            state.invitadoSesion.asistira       = invitado.asistira
            state.invitadoSesion.confirmo       = invitado.confirmo
            state.invitadoSesion.ire_solo       = invitado.ire_solo
            state.invitadoSesion.no_asistira    = invitado.no_asistira
            state.invitadoSesion.qr             = invitado.qr
            // state.invitadoSesion.fecha_confirmo = this.traerFechaActual();
        }
    },

    actions: {

        guardarDatosInvitados({ commit }, invitados) {
            commit('setDatosInvitados', invitados.invitados);
        },

        guardarSesionInvitado({commit}, invitado){
            commit('setSesionInvitados', invitado)
        },

        actualizarDatos({commit}, sesion){
            commit('updateSesionInvitados', sesion)
        }
    },

    modules: {},

    // plugins: [createPersistedState()]

})