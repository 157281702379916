<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>


  </v-app>
</template>

<script>
  import {mapActions , mapGetters} from 'vuex'
  import invitados from '@/invitados.json'; // Importa tu archivo JSON

  export default {
    data() {
      return {
        // isTransparent: true
      };
    },
    // mounted() {
    //   window.addEventListener('scroll', this.handleScroll);ñ
    // },
    // destroyed() {
    //   window.removeEventListener('scroll', this.handleScroll);
    // },

    created(){
      this.init();
    },

    computed:{
      ...mapGetters(['obtenerInvitados']),
    },  

    methods: {
      ...mapActions(['guardarDatosInvitados']),

      init(){
        this.guardarDatosInvitados(invitados);
      },

      // handleScroll() {
      //   this.isTransparent = window.scrollY === 0;
      // }
    }
  };
</script>

<style>
  
  /* .titulos-principales {
    font-family: 'Pacifico', cursive;
    font-size: 40px;
  }

  .nombres-productos {
    font-family: 'Pacifico', cursive;
    font-size: 20px;
  } */


::-webkit-scrollbar {
    width: 5px;
    height : 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
    /* background: transparent; */
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255);
  }

  .shadowCard{
    border-radius: 8px !important; 
    box-shadow: 0 20px 27px 0 rgba(0,0,0,0.2)  !important;
  }

</style>
