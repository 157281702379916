<template>
  <v-main fluid class="fill-height d-flex align-center pa-4 fondo-image" style="background-color: black;">

    <v-row class="justify-center" > 

      <v-col cols="12" sm="7" md="5" lg="4" >
        <!-- <v-card class="pa-4" flat> -->

          <v-card-text class="text-center"  >
            <!-- <div class="texto-invitacion"> 
              Hay momentos en la vida que son muy especiales por si solos, 
              pero al compartirlos con personas tan especiales como tú, 
              se convierten en momentos inolvidables. 
              !Te esperamos en nuestra boda¡
            </div> -->
 
            <!-- <div class="texto-invitacion "> 
              Hay momentos inolvidables que se atesoran en el corazon para siempre.
              Por esa razon, quiero que compartas conmigo esté día tan especial.
            </div> -->

            <!-- <div class="texto-invitacion ">
              ¡El amor nos ha unido en un viaje increíble y ahora queremos compartir 
              el capítulo más emocionante de nuestra historia contigo!
            </div> -->

            <div class="texto-invitacion">{{  textoInvitacion }}</div>
          </v-card-text>

          <br>
          <br>

          <v-card-text class="py-0 px-2 fade-in" v-if="formularioVisible">
            <template >
              <v-form ref="form" v-model="valid" >
                <v-text-field
                  v-model="password"
                  append-icon="lock"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  label="Ingresa código de acceso"
                  placeholder="**********"
                  @click:append="show1 = !show1"
                  @keyup.enter="ingresar"
                  outlined
                  color="white"
                  dark
                  class="pass"
                ></v-text-field>
              </v-form>
            </template>
          </v-card-text>

          <v-card-actions v-if="formularioVisible">
            <v-btn 
              persistent
              color="primary"  
              dark
              block 
              @click="ingresar()" 
              class="fade-in "
            >
              Ingresar
            </v-btn>
          </v-card-actions> 
<!-- 
          <v-footer  transparent>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
          </v-footer> -->
        <!-- </v-card> -->
      </v-col>
    </v-row>  
    
    
  </v-main>
</template>

<script >
  import {mapActions , mapGetters} from 'vuex'
  // import olvidaContra from '@/views/Login/olvida_contra.vue'
  // var md5     = require('md5')

  export default {
    components: {
      // olvidaContra
    },
    data(){
      return{
        login: true, 
        password: '',
        // correo:'',
        // iniciar: false,
        // tipos_registros: [{id:1, nombre:'Docente'}, {id:2, nombre:'Alumno'}],
        // tipo_registro:{ id:null, nombre:''},
       
        show1: false,

				alerta: { activo: false, texto:'', color:'error' },

        valid: false,
        // emailRules    :  [v => !!v || 'requerido'],
        passwordRules    :  [v => !!v || 'requerido'],
        // tipoRules     :   [v => !!v || 'Es requerido'],

        textoInvitacion: "",
        formularioVisible: false,
      }
    },
    async mounted() {
      await this.efectoMaquinaEscribir();
    },

    computed:{
      ...mapGetters(['obtenerInvitados']),
    },  

    methods:{
      ...mapActions(['guardarDatosUsuario']),

      efectoMaquinaEscribir() {
        const textoOriginal =
          "¡El amor nos ha unido en un viaje increíble y ahora queremos compartir el capítulo más emocionante de nuestra historia contigo!";
        let i = 0;

        // Utiliza setInterval para simular el efecto de máquina de escribir
        const intervalo = setInterval(() => {
          this.textoInvitacion += textoOriginal.charAt(i);
          i++;

          // Detén el intervalo cuando se haya escrito todo el texto
          if (i > textoOriginal.length) {
            clearInterval(intervalo);
            this.mostrarBotones();
          }
        }, 80); // 80 Ajusta la velocidad según tus preferencias
      },

      mostrarBotones(){
        this.formularioVisible = true;
      },

      ingresar (){
        
        if(this.password == ''){
          alert('Ingresa tú codigo de acceso.');
          return
        }

        this.$http.post('iniciar_sesion', { password: this.password }).then(response =>{
          if(response.body != undefined && response.body != '' && response.body != []){

            if(response.body[0].nivel == 1){
              this.$router.push({ name:'home' , params:{ invitado: response.body[0]}}) 
            }else{
              this.$router.push({ name:'confirmados', params:{ invitado: response.body[0]} }) 
            }

          }else{
            console.log('error');
            alert('Lo sentimos esté código no tiene acceso.');
          }
        }).catch(error =>{
          console.log('Algo salio mal', error);
          alert('Lo sentimos esté código no tiene acceso.');
        })

        // return ;
        
        // let invitado = this.obtenerInvitados.filter((e) =>{
        //   if(e.contrasenia == this.password){
        //     return e;
        //   }
        // });


        // if(!invitado.length){
        //   alert('Lo sentimos esté código no tiene acceso.');
        //   return
        // }

        // this.$router.push({ name:'home' , params:{ invitado: invitado[0] }}) 
      }
    }
  };


</script>

<style scoped>

  .pass{
    font-size: 30px;
  }

  .fondo-image{
    background: linear-gradient(rgba(0, 0, 0, 0.786), rgba(0, 0, 0, 0.687)), url('~@/assets/smoke.gif');
    /* background: linear-gradient(rgba(0, 0, 0, 0.393), rgba(0, 0, 0, 0.105)), url('~@/assets/banner1.jpg'); */
    background-size: cover;
    background-position: center;
    background-attachment: fixed; 
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; 
  }

  
  .texto-invitacion{
    font-family: 'Great Vibes', cursive;
    font-size: 40px;
    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);  */
    color: white;
    line-height: 1; 
    /* color:#c49f58; */
  }

  .fade-in {
    animation: fade-in 2s;
  }

  @keyframes fade-in {
  from {
    opacity: 0;
    transform: translateX(120px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>



  