import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import es from 'vuetify/es5/locale/es';
import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#c49f58', 
                secondary: '#18332f ', // CASI NEGRO 
                accent: '#ee2737', // ROJO
                error: colors.red.darken1,
                info: colors.blue.darken1,
                success: colors.green.darken2,
                warning: colors.amber.accent4
            },
        },
    },

    lang: {
        locales: { es },
        current: 'es'
    }
});